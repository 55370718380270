
function refreshProjetLink(){
	$('.projetThumbContainer').each(function(){
		$(this).unbind('click');
		$(this).bind('click', function(){
			window.location.href = $(this).attr("data-href");
		})		;
	});
}

function refreshUploadWidget(){
	$('.btnUploadFile').unbind('click');
	$('.btnUploadFile').bind('click', function(){
		$(this).parent().children('input[type=file]').trigger('click');
		return false;
	});
	$('.vich-file input[type=file]').unbind('change');
	$('.vich-file input[type=file]').bind('change', function(e){
	  $(this).parent().children('.filenameLabel').html(e.target.files[0].name);
	});
}

$(document).ready(function(){
	$('#flashContainer').delay(5000).fadeTo(2000, 0, function(){$(this).hide()});
	$('.notification .close').click(function(){
		$(this).parent().fadeTo(500, 0, function(){$(this).hide()});
	});

	$("body").removeClass("preload");

	refreshUploadWidget();

	refreshProjetLink();
     // $(document).tooltip();


	$('#handle').click(function(){
		$(this).parent().parent().find('nav#header-topMenu').toggleClass('opened');
		return false;
	});

	$('#userMenu-button').click(function(event){
		$('#userMenu').toggleClass('opened');
		if($('#userMenu').hasClass('opened')){
			$('#userMenu-button .dropDown').addClass('opened');
			$('#otherUserMenu-button .dropDown').removeClass('opened');
			$('#otherUserMenu').removeClass('opened');
		}
		else{
			$('#userMenu-button .dropDown').removeClass('opened');
		}
		event.stopPropagation();
	});

	$('#otherUserMenu-button').click(function(event){
		$('#otherUserMenu').toggleClass('opened');
		if($('#otherUserMenu').hasClass('opened')){
			$('#otherUserMenu-button .dropDown').addClass('opened');
			$('#userMenu-button .dropDown').removeClass('opened');
			$('#userMenu').removeClass('opened');
		}
		else{
			$('#otherUserMenu-button .dropDown').removeClass('opened');
		}
		event.stopPropagation();
	});


	$('body').click(function(event){
		$('#userMenu-button .dropDown').removeClass('opened');
		$('#userMenu').removeClass('opened');
		$('#otherUserMenu-button .dropDown').removeClass('opened');
		$('#otherUserMenu').removeClass('opened');
	});

	$('input.uncorrect').change(function(){
		$(this).removeClass('uncorrect');
	});

	$('#projetsBtn, #headerHoverMenu').mouseover(function(){
		if($(window).width() > 722){
			$('#headerHoverMenu').stop().show().css('opacity', '0').css('bottom', '0px').animate({
				'opacity' : 1,
				'bottom' : '10px'
			}, 300);
		}
	});
	$('#projetsBtn, #headerHoverMenu').mouseout(function(){
		$('#headerHoverMenu').stop().css('opacity', '1').css('bottom', '10px').animate({
			'opacity' : 0,
			'bottom' : "0px"
		}, 300, function(){
			$('#headerHoverMenu').hide();
		});
	});

	$('#header-topMenu-btnShowCategories').mouseover(function(){
		$('#header-topMenu-categories').show();
		$('#header-topMenu-continents').hide();
	});
	$('#header-topMenu-btnShowContinents').mouseover(function(){
		$('#header-topMenu-categories').hide();
		$('#header-topMenu-continents').show();
	});

	$('.uncorrect').mouseover(function(){
		$(this).parent().children('.errorTooltip').css('opacity', 0).show().animate({'opacity' : 1}, 300);
	});

	$('.uncorrect').mouseout(function(){
		$(this).parent().children('.errorTooltip').show().css('opacity', 1).animate({'opacity' : 0}, 300, function(){$(this).hide();});
	});

	$('.carleft').each(function(){
		var element = $(this);
		var textareaId = $(this).attr("data-id");
		$('#'+textareaId).keydown(function(){
			$(element).html(parseInt($(this).attr("maxLength")) - $(this).val().length);
		});
		$('#'+textareaId).trigger('keydown');
	});

    $('#welcome button.close').click(function(){
        $('#welcome-wrapper').fadeOut();
    });


});
