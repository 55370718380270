jQuery(window).ready(function () {
    _owl_carousel();
});


/** OWL Carousel
 **************************************************************** **/
function _owl_carousel() {

    jQuery(".owl-carousel").each(function() {
      var slider 	= jQuery(this);
      var options 	= slider.attr('data-plugin-options');

      var defaults = {
          margin:                         5,
          stagePadding:                   20,
	  items: 		          	5,
	  itemsCustom: 			false,
	itemsDesktop: 			[1199,4],
	itemsDesktopSmall: 		[980,3],
	itemsTablet: 			[768,2],
	itemsTabletSmall: 		false,
	itemsMobile: 			[479,1],
	singleItem: 			true,
	itemsScaleUp: 			false,

	slideSpeed: 			200,
	paginationSpeed: 	        800,
	rewindSpeed: 			1000,

	autoPlay: 			false,
	stopOnHover: 			false,

	navigation: 			true,
	navigationText: [
	  '<i class="fa fa-angle-left"></i>',
	    '<i class="fa fa-angle-right"></i>'
	],
	  rewindNav: 			true,
          scrollPerPage: 			false,
          slideBy:                      1,


	pagination: 			false,
	paginationNumbers: 		false,

	responsive: 			true,
	responsiveRefreshRate: 	200,
	//responsiveBaseWidth: 	window,

	baseClass: 			"owl-carousel",
	theme: 				"owl-theme",

	lazyLoad: 			false,
	lazyFollow: 			true,
	lazyEffect: 			"fade",

	autoHeight: 			false,

	jsonPath: 				false,
	jsonSuccess: 			false,

	dragBeforeAnimFinish: 	true,
	mouseDrag: 				true,
	touchDrag: 				true,

	transitionStyle: 		false,

	addClassActive: 		false,

	beforeUpdate: 			false,
	afterUpdate: 			false,
	beforeInit: 			false,
	afterInit: 		        false,
	beforeMove: 			false,
	afterMove: 			false,
	afterAction: 			false,
	startDragging: 			false,
	afterLazyLoad: 			false
      };

      var config = $.extend({}, defaults, options, slider.data("plugin-options"));
      slider.owlCarousel(config).addClass("owl-carousel-init");
    });
}
